<template>
  <div class="notFound">
    <h1>Oops!</h1>
    <h2>Sorry, looks like that page doesn’t exist, or it’s been removed from the site.</h2>
    <div class="primary-btn" @click="$router.push(prevRoute)">Back</div>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  data() {
    return {
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.checkPrevRoute(from.fullPath);
    });
  },
  methods: {
    checkPrevRoute(prev) {
      this.prevRoute = prev;
    },
  },
};
</script>

<style lang="scss" scoped>
.notFound {
  margin: 0 53px;
  min-height: calc(100vh - 246px) !important;
  h1 {
    font-size: 7.2rem;
    font-family: $head-font-stack;
    font-weight: 700;
    padding: 0;
    margin: 75px 0 25px 0;
  }
  h2 {
    font-weight: 300;
    font-size: 2.4rem;
    padding: 0;
    margin: 0 0 25px 0;
    max-width: 470px;
  }
}
</style>
